import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// Firebase config (replace with your own)
const firebaseConfig = {
  apiKey: "AIzaSyC8eCP0ynt7jlC9VNeEmUryZTZhF-wrotw",
  authDomain: "eventq-cf6f2.firebaseapp.com",
  projectId: "eventq-cf6f2",
  storageBucket: "eventq-cf6f2.firebasestorage.app",
  messagingSenderId: "6004740862",
  appId: "1:6004740862:web:3bd437ce9984c2c1640baf",
  measurementId: "G-70LKLFXS3Y"
};

const vapidKey = "BN8alse5PFO1K_oeAGr2dMnMqn03Bq6T5_ptm1D1HQungLCgiacZj5iDJbxK_zB63RbqWn2KxhC6lTT0E5iPcvA"


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get messaging instance
const messaging = getMessaging(app);



export { messaging, getToken };

// export const requestFCMToken = async () => {
//   return Notification.requestPermission()
//     .then((permission) => {
//       if (permission === "granted") {
//         return getToken(messaging, { vapidKey })
//       } else {
//         throw new Error("Noficiation not granted")
//       }
//     })
//     .catch((err) => {
//       console.error("Error getting FCM token: ", err)
//       throw err;
//     })
// }

// export const requestFCMToken = async () => {
//   return Notification.requestPermission()
//     .then((permission) => {
//       if (permission === "granted") {
//         return getToken(messaging, { vapidKey })
//       } else {
//         throw new Error("Noficiation not granted")
//       }
//     })
//     .catch((err) => {
//       console.error("Error getting FCM token: ", err)
//       throw err;
//     })
// }

export const requestFCMToken = async () => {
  try {
    // Request notification permission
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      throw new Error("Notification permission not granted.");
      
    }

    // Get the FCM token
    const token = await getToken(messaging, { vapidKey });

    if (token) {
      console.log("FCM Token:", token);
      return token;
    } else {
      console.warn("No FCM token available.");
      throw new Error("Failed to retrieve FCM token.");
    }
  } catch (error) {
    console.error("Error getting FCM token: ", error);
    throw error; // Re-throw the error for handling in the calling function
  }
};
