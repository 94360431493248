export const UserKey = 'user';
export const ratingskey = 'ratings';
export const listingkey = 'listing';
export const calendarKey = 'calendar';
export const CustomerKey = 'customer';
export const VendorKey = 'vendor';
export const bookingKey = 'booking';
export const enquiryKey = 'enquiry';
export const categoryKey = 'category';
export const vendorCouponsKey = 'vendorCoupons';
export const receiptsKey = 'receipts';
export const expensesKey = 'expenses';
export const vendorOrdersKey = 'vendor-orders';
export const informationPolicyKey = 'information-policy';
export const transactionsKey = 'transactions';
export const amenitiesKey = 'amenities';
export const eventTypeKey = 'eventType';
export const packagesKey = 'packages';
export const bannerKey = 'banner';
export const categoryQuestionsKey = 'categoryQuestions';
export const cartKey = 'cart';
export const MakeKey = 'make';
export const ModelKey = 'model';
export const EmployeeTypeKey = 'employee-type';
export const TransmissionKey = 'transmission';
export const FuelTypeKey = 'fuel-type';
export const BodyTypeKey = 'body-type';
export const VehicleTypeKey = 'vehicle-type';
export const ColorKey = 'color';
export const VersionKey = 'version';
export const LeadKey = 'lead';
export const QuestionCategoryKey = 'question-category';
export const InventoryQuestionKey = 'inventory-question';
export const InventoryKey = 'inventory';
export const StateKey = 'state';
export const CityKey = 'city';
export const MainClientKey = 'main-client';
export const ClientKey = 'client';
export const ServicePriceKey = 'service-price';
export const SettingsKey = 'settings';
export const InventoryAgentKey = 'inventory-agent';
export const InvoiceKey = 'invoice';
export const ClientExecutiveKey = 'client-executive';
export const RegionKey = 'region';
export const TransactionKey = 'transaction';
export const BillsKey = 'bills';
export const VehicleInspectionReportKey = 'vehicle-inspection-report';
export const InventoryOperationsKey = 'inventory-operations';
export const InventoryQcKey = 'inventory-qc';
export const InventoryPricingKey = 'inventory-pricing';
export const paymentsKey = 'payments';
export const bookingDateChangeKey = 'booking-date-change-request';
export const bookingPaymentsKey = 'booking-payments-list';
export const settingsKey = 'settings';
export const pagesKey = 'pages';
export const pagesCategoryKey = 'pages-category';
export const invoiceKey = 'invoice';
export const quotationKey = 'quotation';
export const adminRolesKey = 'admin-roles';
export const subAdminKey = 'sub-admin';