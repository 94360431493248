import React from 'react';
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  REDIRECT_COMPONENT_KEY
} from 'configs/AppConfig';
import * as routeKeys from 'constants/RoutesKeys';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/authentication/login'))
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    )
  },
  {
    key: 'VehicleInspectionReportKey',
    path: `/reports/inspection/:certificationId`,
    blankLayout: true,
    component: React.lazy(() =>
      import('views/app-views/pages/vehicle-inspection-report')
    )
  }
  // {
  //   key: 'register',
  //   path: `${AUTH_PREFIX_PATH}/register`,
  //   component: React.lazy(() =>
  //     import('views/auth-views/authentication/register')
  //   )
  // },
];

export const protectedRoutes = [
  {
    key: 'pages',
    isPage: true,
    path: `${APP_PREFIX_PATH}/pages`,
    component: React.lazy(() => import('views/app-views/pages'))
  },
  {
    key: 'pages.profile',
    isPage: true,
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import('views/app-views/pages/profile'))
  },
  {
    key: REDIRECT_COMPONENT_KEY,
    path: `${APP_PREFIX_PATH}/dashboards`,
    component: React.lazy(() => import('views/app-views/dashboards/dashboard'))
  },
  // {
  //   key: routeKeys.UserKey,
  //   path: `${APP_PREFIX_PATH}/dashboards/user-list/*`,
  //   component: React.lazy(() => import('views/app-views/dashboards/user'))
  // },
  {
    key: routeKeys.subAdminKey,
    path: `${APP_PREFIX_PATH}/dashboards/subadmins-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/sub-admin'))
  },
  {
    key: routeKeys.adminRolesKey,
    path: `${APP_PREFIX_PATH}/dashboards/admin-roles-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/admin-roles'))
  },
  {
    key: routeKeys.UserKey,
    path: `${APP_PREFIX_PATH}/dashboards/user/customer-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/user/customer'))
  },
  {
    key: routeKeys.VendorKey,
    path: `${APP_PREFIX_PATH}/dashboards/user/vendor-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/user/vendor'))
  },
  {
    key: routeKeys.SettingsKey,
    path: `${APP_PREFIX_PATH}/dashboards/settings-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/settings'))
  },
  {
    key: routeKeys.listingkey,
    path: `${APP_PREFIX_PATH}/dashboards/listing-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/listing')
    )
  },
  {
    key: routeKeys.listingkey,
    path: `${APP_PREFIX_PATH}/dashboards/listing-list-two/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/listing-list')
    )
  },
  {
    key: routeKeys.bookingKey,
    path: `${APP_PREFIX_PATH}/dashboards/booking-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/booking')
    )
  },
  {
    key: routeKeys.transactionsKey,
    path: `${APP_PREFIX_PATH}/dashboards/transactions-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/transactions')
    )
  },
  {
    key: routeKeys.amenitiesKey,
    path: `${APP_PREFIX_PATH}/dashboards/amenities-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/amenities')
    )
  },
  {
    key: routeKeys.eventTypeKey,
    path: `${APP_PREFIX_PATH}/dashboards/event-type-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/event-type')
    )
  },
  {
    key: routeKeys.categoryQuestionsKey,
    path: `${APP_PREFIX_PATH}/dashboards/category-questions-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/category-questions')
    )
  },
  {
    key: routeKeys.bannerKey,
    path: `${APP_PREFIX_PATH}/dashboards/banner-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/banner')
    )
  },
  {
    key: routeKeys.categoryKey,
    path: `${APP_PREFIX_PATH}/dashboards/category-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/category')
    )
  },
  {
    key: routeKeys.packagesKey,
    path: `${APP_PREFIX_PATH}/dashboards/packages-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/packages')
    )
  },
  {
    key: routeKeys.cartKey,
    path: `${APP_PREFIX_PATH}/dashboards/cart-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/cart')
    )
  },
  {
    key: routeKeys.ratingskey,
    path: `${APP_PREFIX_PATH}/dashboards/ratings-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/rating')
    )
  },
  {
    key: routeKeys.vendorCouponsKey,
    path: `${APP_PREFIX_PATH}/dashboards/vendor-coupons-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/vendor-coupons')
    )
  },
  {
    key: routeKeys.receiptsKey,
    path: `${APP_PREFIX_PATH}/dashboards/receipts-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/receipts')
    )
  },
  {
    key: routeKeys.expensesKey,
    path: `${APP_PREFIX_PATH}/dashboards/expenses-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/expenses')
    )
  },
  {
    key: routeKeys.vendorOrdersKey,
    path: `${APP_PREFIX_PATH}/dashboards/vendor-orders-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/vendor-orders')
    )
  },
  {
    key: routeKeys.paymentsKey,
    path: `${APP_PREFIX_PATH}/dashboards/payments-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/payments-list')
    )
  },
  {
    key: routeKeys.enquiryKey,
    path: `${APP_PREFIX_PATH}/dashboards/enquiry-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/enquiry')
    )
  },
  {
    key: routeKeys.invoiceKey,
    path: `${APP_PREFIX_PATH}/dashboards/invoice-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/invoice')
    )
  },
  {
    key: routeKeys.informationPolicyKey,
    path: `${APP_PREFIX_PATH}/dashboards/information-policy-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/information-policy')
    )
  },
  {
    key: routeKeys.quotationKey,
    path: `${APP_PREFIX_PATH}/dashboards/quotation-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/quotation')
    )
  },
  {
    key: routeKeys.calendarKey,
    path: `${APP_PREFIX_PATH}/dashboards/booking-calendar/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/booking-calendar')
    )
  },
  {
    key: routeKeys.bookingDateChangeKey,
    path: `${APP_PREFIX_PATH}/dashboards/booking-date-change-request-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/booking-date-change')
    )
  },
  {
    key: routeKeys.CustomerKey,
    path: `${APP_PREFIX_PATH}/dashboards/booking-payments-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/booking-payments-list')
    )
  },
  {
    key: routeKeys.settingsKey,
    path: `${APP_PREFIX_PATH}/dashboards/settings/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/settings')
    )
  },
  {
    key: routeKeys.pagesKey,
    path: `${APP_PREFIX_PATH}/dashboards/pages-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/pages')
    )
  },
  {
    key: routeKeys.pagesCategoryKey,
    path: `${APP_PREFIX_PATH}/dashboards/pages-category-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/pages-category')
    )
  },
];
